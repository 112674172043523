import React from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { PATH } from "~/constant/path";
import { Container } from "~/atoms/Container";
import { ArticleContent } from "~/organisms/ArticleContent";
import { ArticleSidebar } from "~/organisms/ArticleSidebar";
import { ArticleHeader } from "~/molecules/ArticleHeader";
import { CaseArticleBody } from "~/molecules/CaseArticleBody";
import { ArticleFooter } from "~/molecules/ArticleFooter";
import { ArticleListSection } from "~/molecules/ArticleListSection";
import { CaseCard } from "~/molecules/CaseCard";
import { RecommendedArticles } from "~/molecules/RecommendedArticles";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

interface Props {
  path: string;
  pageContext: {
    contentfulId: string;
    regex: string;
  };
  data: {
    site: GatsbyTypes.Site;
    caseArticle: GatsbyTypes.ContentfulCase;
    allCases: GatsbyTypes.ContentfulCaseConnection;
    recommendCases: GatsbyTypes.ContentfulCaseConnection;
    recommendReports: GatsbyTypes.ContentfulReportConnection;
  };
}

const idsForCanonical = [`5swfIV0c3WWN9bW0wWGsEa`, `vyOdzRf05mmPsjcUis5hP`];
const ArticleCase: React.FC<Props> = ({ path, pageContext, data }) => {
  const { site, caseArticle, allCases, recommendCases, recommendReports } = data;
  const requiredCanonical = idsForCanonical.includes(pageContext.contentfulId);
  const breadCrumbItems = [
    PATH.TOP,
    PATH.CASES,
    {
      name: caseArticle.title,
    },
  ];
  const ogpDescription = caseArticle?.ogpDescription?.internal.content;
  const recommendCaseList = recommendCases.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/cases/${edge.node.contentful_id}`,
    };
  });
  const recommendReportList = recommendReports.edges.map((edge) => {
    return {
      node: edge.node,
      href: `/events/${edge.node.eventCategory?.slug}/${
        (edge.node.eventCategory?.slug === `award` && `interview${edge.node.awardYear}/`) || ``
      }${edge.node.contentful_id}`,
    };
  });

  return (
    <>
      <Layout
        title={caseArticle.title || ``}
        ogpDescription={ogpDescription || ``}
        ogpImageUrl={(caseArticle.ogpImage && `https:${caseArticle?.ogpImage?.file?.url}`) || ``}
        ogpTitle={caseArticle.ogpTitle || ``}
        canonicalUrl={(requiredCanonical && `${site?.siteMetadata?.siteUrl}/cases/${pageContext.contentfulId}/`) || ``}
        breadCrumbItems={breadCrumbItems}
        breadCrumbBgColor={colors.creamGray2}
      >
        <div className="articleWrapper">
          <Container>
            <ArticleContent>
              <ArticleHeader
                categoryName={PATH.CASES.name}
                companyName={caseArticle.company?.name || ``}
                title={caseArticle.title || ``}
                date={caseArticle.date}
              />
              <CaseArticleBody article={caseArticle} />
              <ArticleFooter url={path} title={caseArticle.title || ``} ogpTitle={caseArticle.ogpTitle || ``} />
            </ArticleContent>
            <ArticleSidebar
              articles={allCases.edges.map((edge) => {
                return {
                  node: edge.node,
                  href: `/cases/${edge.node.contentful_id}`,
                };
              })}
              category={PATH.CASES.name}
            />
          </Container>
        </div>
        <ArticleListSection title={PATH.CASES.name}>
          <ul className="caseList">
            {allCases.edges.map((edge) => {
              return (
                <CaseCard
                  key={edge.node.contentful_id}
                  article={edge.node}
                  href={`/cases/${edge.node.contentful_id}`}
                />
              );
            })}
          </ul>
        </ArticleListSection>
        <ArticleListSection title="こちらもおすすめ">
          <RecommendedArticles articles={[...recommendCaseList, ...recommendReportList]} />
        </ArticleListSection>
      </Layout>
      <style jsx>{`
        .articleWrapper {
          background-color: ${colors.creamGray};
        }
        .articleWrapper :global(.container) {
          padding: 30px 0 50px;
        }
        .caseList {
          position: relative;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
        @media (${breakPoints.sp}) {
          .articleWrapper :global(.container) {
            flex-direction: column;
            padding: 0 0 50px;
          }
        }
      `}</style>
    </>
  );
};

export default ArticleCase;

export const caseArticleQuery = graphql`
  query CaseArticle($contentfulId: String!, $regex: String) {
    site: site {
      siteMetadata {
        siteUrl
      }
    }
    caseArticle: contentfulCase(contentful_id: { eq: $contentfulId }) {
      id
      contentful_id
      title
      ogpTitle
      date
      mainContents {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            file {
              url
            }
          }
        }
      }
      headContent {
        raw
      }
      company {
        name
        employees
        employmentType
        industry
        location
        logo {
          fluid {
            src
          }
        }
      }
      effect
      problem
      ogpDescription {
        internal {
          content
        }
      }
      ogpImage {
        fluid(maxWidth: 900, quality: 100) {
          src
          aspectRatio
        }
        file {
          details {
            image {
              height
              width
            }
          }
          url
        }
      }
    }
    allCases: allContentfulCase(
      filter: { contentful_id: { ne: $contentfulId } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          contentful_id
          title
          ogpImage {
            fluid(maxWidth: 767) {
              src
              aspectRatio
            }
          }
          company {
            name
          }
          date
        }
      }
    }
    recommendCases: allContentfulCase(
      filter: { contentful_id: { ne: $contentfulId }, tag: { elemMatch: { slug: { regex: $regex } } } }
    ) {
      edges {
        node {
          id
          contentful_id
          title
          ogpImage {
            fluid(maxWidth: 767) {
              src
              aspectRatio
            }
          }
          company {
            name
          }
          date
        }
      }
    }
    recommendReports: allContentfulReport(
      filter: { contentful_id: { ne: $contentfulId }, tag: { elemMatch: { slug: { regex: $regex } } } }
    ) {
      edges {
        node {
          id
          contentful_id
          title
          ogpImage {
            fluid(maxWidth: 767) {
              src
              aspectRatio
            }
          }
          companyName
          awardYear
          eventCategory {
            slug
          }
          date
          tag {
            name
            slug
          }
        }
      }
    }
  }
`;
