import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { CaseArticleCompany } from "~/atoms/CaseArticleCompany";
import { CaseArticleProblemAndEffect } from "~/atoms/CaseArticleProblemAndEffect";
import { Options } from "~/molecules/BaseArticleBody";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

export const CaseArticleBody: React.FC<{
  article: GatsbyTypes.ContentfulCase;
}> = ({ article }) => {
  const { headContent, mainContents, company, ogpImage, effect, problem } = article;

  return (
    <>
      <style jsx>{`
        .mainContent {
          margin: 0 0 30px;
        }

        .eyeCatch {
          ${ogpImage!.fluid && `background-image: url(${ogpImage?.fluid?.src});`}
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          ${ogpImage && ogpImage.fluid && `padding-top: ${(1 / ogpImage.fluid?.aspectRatio) * 100}%;`}
          position: relative;
          width: 100%;
        }

        .mainContent :global(h2) {
          border-left: solid 3px ${colors.brand};
          color: ${colors.brand};
          font-size: 21px;
          margin: 40px;
          padding: 0 0 0 20px;
        }

        .mainContent :global(h3) {
          font-size: 18px;
          border-bottom: solid 1px ${colors.defaultText};
          margin: 40px;
          padding: 0 0 10px;
        }

        .mainContent :global(h4) {
          font-size: 18px;
          font-weight: 500;
          margin: 40px;
        }

        .mainContent :global(p) {
          font-size: 16px;
          margin: 25px 40px;
        }

        .mainContent :global(p:empty) {
          margin: 0;
        }

        .mainContent :global(.embeddedImageWrapper) {
          margin: 0 40px;
          width: calc(100% - 80px);
        }

        .mainContent :global(.embeddedImageWrapper img) {
          display: block;
          max-width: 100%;
        }

        .mainContent :global(.embeddedImageWrapper .description) {
          color: ${colors.littleBrightGray};
          font-size: 14px;
        }

        .mainContent :global(.dropShadowButtonWrapper) {
          display: flex;
          margin: 50px auto;
          text-align: center;
        }

        .mainContent :global(.dropShadowButtonWrapper a) {
          display: block;
          width: 100%;
        }

        .mainContent :global(.quote) {
          background-position: 15px 15px, calc(100% - 15px) calc(100% - 15px);
          background-repeat: no-repeat;
          margin: 25px 40px;
          padding: 15px 30px;
          font-style: italic;
        }

        .mainContent :global(.quote p) {
          margin: 25px 0;
        }

        .mainContent :global(p > b) {
          font-weight: 600;
        }

        @media (${breakPoints.sp}) {
          .mainContent :global(h2) {
            font-size: 18px;
            margin: 30px 20px;
          }

          .mainContent :global(h3) {
            font-size: 17px;
            margin: 30px 20px;
          }

          .mainContent :global(h4) {
            font-size: 16px;
            margin: 30px 20px;
          }

          .mainContent :global(p) {
            font-size: 15px;
            margin: 25px 20px;
          }

          .mainContent :global(.embeddedImageWrapper) {
            margin: 0 20px;
            text-align: center;
            width: calc(100% - 40px);
          }

          .mainContent :global(.quote) {
            margin: 25px 20px;
          }
        }
      `}</style>
      <div className="mainContent">
        <div className="eyeCatch" />
        {headContent && renderRichText(headContent, Options)}
        {company && <CaseArticleCompany company={company} />}
        <CaseArticleProblemAndEffect problems={problem} effects={effect} />
        {mainContents && renderRichText(mainContents, Options)}
      </div>
    </>
  );
};
