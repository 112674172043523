import React from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

interface Props {
  children: React.ReactNode;
}

export const ArticleContent: React.FC<Props> = ({ children }) => {
  return (
    <>
      <style jsx>{`
        .article {
          background-color: ${colors.white};
          border-top: solid 5px ${colors.brand};
          margin: 0 30px;
        }

        @media (${breakPoints.sp}) {
          .article {
            margin: 30px 0 0 0;
            width: 100%;
          }
        }

        @media (${breakPoints.pc}) {
          .article {
            border-radius: 5px;
            margin: 0 30px 0 0;
            width: calc(100% - 250px);
          }
        }
      `}</style>
      <article className="article">{children}</article>
    </>
  );
};
