import React from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

export const CaseArticleProblemAndEffect: React.FC<{
  problems: Pick<GatsbyTypes.ContentfulCase, "problem">[];
  effects: Pick<GatsbyTypes.ContentfulCase, "effect">[];
}> = ({ problems, effects }) => {
  return (
    <>
      <style jsx>{`
        .caseArticleProblemAndEffect {
          border-top: 1px solid ${colors.gray3};
          border-bottom: 1px solid ${colors.gray3};
          display: flex;
          margin: 40px 0;
        }

        .caseArticleProblemContainer {
          border-right: 1px solid ${colors.gray3};
          padding: 20px 40px;
          width: 50%;
        }

        .caseArticleEffectContainer {
          padding: 20px 40px;
          width: 50%;
        }

        .caseArticleProblemAndEffect_problemTitle {
          font-size: 18px;
          font-weight: 500;
          margin: 0 0 10px;
          text-align: center;
        }

        .caseArticleProblemAndEffect_effectTitle {
          color: ${colors.orange};
          font-size: 18px;
          font-weight: 500;
          margin: 0 0 10px;
          text-align: center;
        }

        .caseArticleProblemAndEffect_list {
          position: relative;
        }

        .caseArticleProblemAndEffect_problemListItem,
        .caseArticleProblemAndEffect_effectListItem {
          font-size: 15px;
          margin: 5px 0;
          padding: 0 0 0 15px;
          position: relative;
        }

        .caseArticleProblemAndEffect_problemListItem:before,
        .caseArticleProblemAndEffect_effectListItem:before {
          border-radius: 50%;
          content: "";
          display: block;
          height: 6px;
          left: 0;
          margin-right: 10px;
          position: absolute;
          top: 10px;
          width: 6px;
        }

        .caseArticleProblemAndEffect_problemListItem:before {
          background-color: ${colors.defaultText};
        }

        .caseArticleProblemAndEffect_effectListItem:before {
          background-color: ${colors.brand};
        }

        @media (${breakPoints.sp}) {
          .caseArticleProblemAndEffect {
            flex-direction: column;
          }

          .caseArticleProblemContainer,
          .caseArticleEffectContainer {
            border: none;
            border-bottom: 1px solid ${colors.gray3};
            padding: 20px;
            width: 100%;
          }
        }
      `}</style>
      <div className="caseArticleProblemAndEffect">
        <div className="caseArticleProblemContainer">
          <div className="caseArticleProblemAndEffect_problemTitle">課題</div>
          <ul className="caseArticleProblemAndEffect_list">
            {problems.map((ploblem) => (
              <li key={`Problem_${ploblem}`} className="caseArticleProblemAndEffect_problemListItem">
                {ploblem}
              </li>
            ))}
          </ul>
        </div>
        {effects && (
          <div className="caseArticleEffectContainer">
            <div className="caseArticleProblemAndEffect_effectTitle">効果</div>
            <ul className="caseArticleProblemAndEffect_list">
              {effects.map((effect) => (
                <li key={`Problem_${effect}`} className="caseArticleProblemAndEffect_effectListItem">
                  {effect}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
