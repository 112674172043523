import React from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

export const CaseArticleCompany: React.FC<{ company: GatsbyTypes.ContentfulCompany }> = ({ company }) => {
  return (
    <>
      <style jsx>{`
        .companyContainer {
          align-items: center;
          background-color: ${colors.creamGray};
          display: flex;
          margin: 0 40px;
          padding: 15px;
          width: calc(100% - 80px);
        }

        .companyLogo {
          background-color: ${colors.white};
          background-image: url(${company.logo?.fluid?.src || ""});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          height: 150px;
          margin: 0 30px 0 0;
          padding: 30px;
          width: 200px;
        }

        .companyDetails {
          width: calc(100% - 230px);
        }

        .companyName {
          font-size: 15px;
          font-weight: 500;
        }

        .companyDetails_table {
          border: none;
          border-collapse: collapse;
          font-size: 13px;
          padding: 0;
        }

        .companyDetails_tableRow {
          text-align: left;
          vertical-align: top;
        }

        .companyDetails_tableHead {
          font-weight: bold;
          margin: 0;
          width: 80px;
        }

        .companyDetails_tableCell {
          margin: 0;
        }

        @media (${breakPoints.sp}) {
          .companyContainer {
            background-color: ${colors.creamGray};
            flex-direction: column;
            margin: 0 20px;
            padding: 15px;
            width: calc(100% - 40px);
          }

          .companyLogo {
            display: block;
            margin: 0;
            width: 100%;
          }

          .companyDetails {
            width: 100%;
          }

          .companyName {
            font-size: 16px;
            margin: 10px 0;
          }
        }
      `}</style>
      <div className="companyContainer">
        <div className="companyLogo" />
        <div className="companyDetails">
          <div className="companyName">{company.name}</div>
          <table className="companyDetails_table">
            <tbody>
              <tr className="companyDetails_tableRow">
                <th className="companyDetails_tableHead">業種</th>
                <td className="companyDetails_tableCell">{company.industry}</td>
              </tr>
              <tr className="companyDetails_tableRow">
                <th className="companyDetails_tableHead">所在地</th>
                <td className="companyDetails_tableCell">{company.location}</td>
              </tr>
              <tr className="companyDetails_tableRow">
                <th className="companyDetails_tableHead">従業員数</th>
                <td className="companyDetails_tableCell">{company.employees}</td>
              </tr>
              <tr className="companyDetails_tableRow">
                <th className="companyDetails_tableHead">採用職種</th>
                <td className="companyDetails_tableCell">{company.employmentType}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
