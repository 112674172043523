import React from "react";
import dayjs from "dayjs";
import { Seminar } from "~/constant/seminar";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import CalendarSvg from "~/images/calendar.svg";
import PinBrandSvg from "~/images/pin_brand.svg";

interface Props {
  categoryName: string;
  companyName?: string;
  title: string;
  date?: string;
  eventDetail?: {
    startedAt: string;
    endedAt: string;
    location: string;
  };
  seminar?: Seminar;
}
export const ArticleHeader: React.FC<Props> = ({ categoryName, companyName, title, date, eventDetail, seminar }) => {
  const eventStartAt = eventDetail && dayjs(eventDetail.startedAt);

  return (
    <>
      <div className="articleHeader">
        <div className="topWrapper">
          <div className="category">{categoryName}</div>
          <div className="company">{companyName}</div>
        </div>
        <h1 className="title">{title}</h1>
        {eventDetail && (
          <div className="eventContainer">
            {seminar && <div className="receptionStatus">{seminar.label}</div>}{" "}
            <div className="eventDetail">
              <span className="eventTime">{`${eventStartAt?.format("YYYY年M月D日")} ${eventStartAt?.format(
                "HH:mm"
              )}〜${dayjs(eventDetail.endedAt).format("HH:mm")}`}</span>
              <span className="location">{eventDetail.location}</span>
            </div>
          </div>
        )}
      </div>
      <style jsx>{`
        .articleHeader {
          padding: 30px 40px;
        }
        .topWrapper {
          align-items: center;
          display: flex;
          margin: 0;
        }
        .category {
          background-color: ${colors.brand};
          border-radius: 4px;
          color: ${colors.white};
          font-size: 14px;
          font-weight: 500;
          margin: 0 10px 0 0;
          padding: 0 10px;
        }
        .company {
          font-size: 15px;
          font-weight: 500;
        }
        .title {
          font-size: 24px;
          line-height: 1.35;
          margin: 10px 0 0;
        }
        .eventContainer {
          align-items: center;
          background-color: ${colors.creamGray};
          border-radius: 3px;
          display: flex;
          margin: 20px 0 0;
          padding: 15px;
        }
        .receptionStatus {
          align-items: center;
          ${seminar && `background-color: ${seminar.baseColor};`} border-radius: 4px;
          color: ${colors.white};
          display: flex;
          flex: 0 0 auto;
          width: 80px;
          height: 80px;
          justify-content: center;
        }
        .eventDetail {
          padding: 0 0 0 15px;
          width: calc(100% - 80px);
        }
        .eventTime {
          display: block;
          font-size: 13px;
          border-bottom: solid 2px ${colors.creamGray2};
          padding: 5px 0 5px 18px;
          position: relative;
        }
        .eventTime:before {
          content: "";
          background-image: url(${CalendarSvg});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 13px;
          height: 13px;
          position: absolute;
          left: 0;
          top: 9px;
        }
        .location {
          display: block;
          font-size: 13px;
          margin: 5px 0;
          padding: 0 0 0 18px;
          position: relative;
        }
        .location:before {
          content: "";
          background-image: url(${PinBrandSvg});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 13px;
          height: 13px;
          position: absolute;
          left: 0;
          top: 4px;
        }
        .dateContainer {
          display: flex;
          margin: 10px 0 0;
          letter-spacing: 0.08rem;
        }
        .date {
          color: ${colors.littleBrightGray};
          line-height: 1;
          margin: 0 10px 0 0;
          padding: 0 10px 0 0;
        }
        @media (${breakPoints.sp}) {
          .articleHeader {
            padding: 20px;
          }
          .title {
            font-size: 20px;
          }
          .date {
            display: block;
            border-right: none;
            margin: 0 0 5px;
          }
        }
      `}</style>
    </>
  );
};
