import React from "react";
import { ShareToSNS } from "~/atoms/ShareToSNS";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

interface Props {
  ogpTitle?: string;
  title: string;
  url: string;
}

export const ArticleFooter: React.FC<Props> = ({ ogpTitle, title, url }) => {
  return (
    <>
      <style jsx>{`
        .articleFooter {
          border-top: 1px solid ${colors.gray};
          display: flex;
          padding: 0 40px;
        }
        .snsWrapper {
          padding: 20px 0;
        }
        @media (${breakPoints.pc}) {
          .articleFooter {
            padding: 0 40px;
          }
          .snsWrapper {
            border-right: 1px solid ${colors.gray};
          }
        }
        @media (${breakPoints.sp}) {
          .articleFooter {
            padding: 0 20px;
          }
        }
      `}</style>
      <div className="articleFooter">
        <div className="snsWrapper">
          <ShareToSNS url={url} title={ogpTitle || title} />
        </div>
      </div>
    </>
  );
};
