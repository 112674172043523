import React from "react";
import { Link } from "gatsby";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import ThumbsBlankJpg from "~/images/thumbBlank.jpg";

type Props = {
  article: GatsbyTypes.ContentfulCase;
  href: string;
};

export const CaseCard: React.FC<Props> = ({ article, href }) => {
  const { company, ogpImage, title } = article;

  return (
    <>
      <li className="card">
        <Link to={href}>
          <div className="cover" />
          <div className="body">
            <h2 className="title">{title}</h2>
            {company && <div className="companyName">{company.name}</div>}
          </div>
        </Link>
      </li>
      <style jsx>{`
        .card {
          background-color: ${colors.white};
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          margin: 15px;
          transition: box-shadow 0.3s ease;
          width: calc(100% / 3 - 30px);
        }
        .card:hover {
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
        }
        .card:hover .cover {
          opacity: 0.8;
        }
        .cover {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(${ogpImage ? ogpImage?.fluid?.src : ThumbsBlankJpg});
          border-radius: 6px 6px 0 0;
          padding-top: 64%;
          transition-property: background-size, opacity;
          transition-duration: 0.4s;
          transition-timing-function: ease;
          width: 100%;
          height: 0;
        }
        .body {
          color: ${colors.defaultText};
          padding: 20px;
        }
        .title {
          font-size: 15px;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.015em;
        }
        .companyName {
          font-size: 13px;
          margin-top: 10px;
        }
        @media (${breakPoints.sp}) {
          .card {
            width: 100%;
            margin: 15px 0;
          }
        }
      `}</style>
    </>
  );
};
